.spin {
    animation: spin 1s infinite linear;
    display: inline-block;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes jelly {
    from, to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(.9, 1.1);
        transform: scale(.9, 1.1);
    }

    50% {
        -webkit-transform: scale(1.1, .9);
        transform: scale(1.1, .9);
    }

    75% {
        -webkit-transform: scale(.95, 1.05);
        transform: scale(.95, 1.05);
    }
}

