/*!
Pure v1.0.0
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/yahoo/pure/blob/master/LICENSE.md
*/
/*csslint box-model:false*/
/*
Box-model set to false because we're setting a height on select elements, which
also have border and padding. This is done because some browsers don't render
the padding. We explicitly set the box-model for select elements to border-box,
so we can ignore the csslint warning.
*/

.pure-form input[type='text'],
.pure-form input[type='password'],
.pure-form input[type='email'],
.pure-form input[type='url'],
.pure-form input[type='date'],
.pure-form input[type='month'],
.pure-form input[type='time'],
.pure-form input[type='datetime'],
.pure-form input[type='datetime-local'],
.pure-form input[type='week'],
.pure-form input[type='number'],
.pure-form input[type='search'],
.pure-form input[type='tel'],
.pure-form input[type='color'],
.pure-form select,
.pure-form textarea {
    padding: 0.5em 0.6em;
    display: inline-block;
    border: 1px solid lightgrey;
    border-radius: 4px;
    vertical-align: middle;
    width: 300px;
    box-sizing: border-box;
    transition: border-color 200ms ease-in-out;
}
.pure-form input::placeholder {
    color: lightgrey;
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/
.pure-form input:not([type]) {
    padding: 0.5em 0.6em;
    display: inline-block;
    border: 1px solid lightgrey;
    border-radius: 4px;
}

/* Chrome (as of v.32/34 on OS X) needs additional room for color to display. */
/* May be able to remove this tweak as color inputs become more standardized across browsers. */
.pure-form input[type='color'] {
    padding: 0.2em 0.5em;
}

.pure-form input[type='text']:focus,
.pure-form input[type='password']:focus,
.pure-form input[type='email']:focus,
.pure-form input[type='url']:focus,
.pure-form input[type='date']:focus,
.pure-form input[type='month']:focus,
.pure-form input[type='time']:focus,
.pure-form input[type='datetime']:focus,
.pure-form input[type='datetime-local']:focus,
.pure-form input[type='week']:focus,
.pure-form input[type='number']:focus,
.pure-form input[type='search']:focus,
.pure-form input[type='tel']:focus,
.pure-form input[type='color']:focus,
.pure-form select:focus,
.pure-form textarea:focus {
    border: 1px solid #129fea;
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/
.pure-form input:not([type]):focus {
    outline: 0;
    border-color: #129fea;
}

.pure-form input[type='file']:focus,
.pure-form input[type='radio']:focus,
.pure-form input[type='checkbox']:focus {
    outline: thin solid #129fea;
    outline: 1px auto #129fea;
}
.pure-form .pure-checkbox,
.pure-form .pure-radio {
    margin: 0.5em 0;
    display: block;
}

.pure-form input[type='text'][disabled],
.pure-form input[type='password'][disabled],
.pure-form input[type='email'][disabled],
.pure-form input[type='url'][disabled],
.pure-form input[type='date'][disabled],
.pure-form input[type='month'][disabled],
.pure-form input[type='time'][disabled],
.pure-form input[type='datetime'][disabled],
.pure-form input[type='datetime-local'][disabled],
.pure-form input[type='week'][disabled],
.pure-form input[type='number'][disabled],
.pure-form input[type='search'][disabled],
.pure-form input[type='tel'][disabled],
.pure-form input[type='color'][disabled],
.pure-form select[disabled],
.pure-form textarea[disabled] {
    cursor: not-allowed;
    background-color: #eaeded;
    color: #cad2d3;
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/
.pure-form input:not([type])[disabled] {
    cursor: not-allowed;
    background-color: #eaeded;
    color: #cad2d3;
}
.pure-form input[readonly],
.pure-form select[readonly],
.pure-form textarea[readonly] {
    background-color: #eee; /* menu hover bg color */
    color: #777; /* menu text color */
    border-color: whitesmoke;
}

.pure-form select {
    /* Normalizes the height; padding is not sufficient. */
    border: 1px solid lightgrey;
    background-color: white;
}
.pure-form select[multiple] {
    height: auto;
}
.pure-form label {
    margin: 0.5em 0 0.2em;
}
.pure-form fieldset {
    margin: 0;
    padding: 0.35em 0 0.75em;
    border: 0;
}
.pure-form legend {
    display: block;
    width: 100%;
    padding: 0.3em 0;
    margin-bottom: 0.3em;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
}

.pure-form-stacked input[type='text'],
.pure-form-stacked input[type='password'],
.pure-form-stacked input[type='email'],
.pure-form-stacked input[type='url'],
.pure-form-stacked input[type='date'],
.pure-form-stacked input[type='month'],
.pure-form-stacked input[type='time'],
.pure-form-stacked input[type='datetime'],
.pure-form-stacked input[type='datetime-local'],
.pure-form-stacked input[type='week'],
.pure-form-stacked input[type='number'],
.pure-form-stacked input[type='search'],
.pure-form-stacked input[type='tel'],
.pure-form-stacked input[type='color'],
.pure-form-stacked input[type='file'],
.pure-form-stacked select,
.pure-form-stacked label,
.pure-form-stacked textarea {
    display: block;
    margin: 0.25em 0;
    width: 100%;
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/
.pure-form-stacked input:not([type]) {
    display: block;
    margin: 0.25em 0;
}
.pure-form-aligned input,
.pure-form-aligned textarea,
.pure-form-aligned select,
/* NOTE: pure-help-inline is deprecated. Use .pure-form-message-inline instead. */
    .pure-form-aligned
    .pure-help-inline,
.pure-form-message-inline {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: middle;
}
.pure-form-aligned textarea {
    vertical-align: top;
}
.pure-form-stacked .pure-control-group {
    margin-bottom: 0.7em;
}

/* Aligned Forms */
.pure-form-aligned .pure-control-group {
    margin-bottom: 0.5em;
}
.pure-form-aligned .pure-control-group label {
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    width: 10em;
    margin: 0 1em 0 0;
}
.pure-form-aligned .pure-controls {
    margin: 1.5em 0 0 11em;
}

/* Rounded Inputs */
.pure-form input.pure-input-rounded,
.pure-form .pure-input-rounded {
    border-radius: 2em;
    padding: 0.5em 1em;
}

/* Grouped Inputs */
.pure-form .pure-group fieldset {
    margin-bottom: 10px;
}
.pure-form .pure-group input,
.pure-form .pure-group textarea {
    display: block;
    padding: 10px;
    margin: 0 0 -1px;
    border-radius: 0;
    position: relative;
    top: -1px;
}
.pure-form .pure-group input:focus,
.pure-form .pure-group textarea:focus {
    z-index: 3;
}
.pure-form .pure-group input:first-child,
.pure-form .pure-group textarea:first-child {
    top: 1px;
    border-radius: 4px 4px 0 0;
    margin: 0;
}
.pure-form .pure-group input:first-child:last-child,
.pure-form .pure-group textarea:first-child:last-child {
    top: 1px;
    border-radius: 4px;
    margin: 0;
}
.pure-form .pure-group input:last-child,
.pure-form .pure-group textarea:last-child {
    top: -2px;
    border-radius: 0 0 4px 4px;
    margin: 0;
}
.pure-form .pure-group button {
    margin: 0.35em 0;
}

.pure-form .pure-input-1 {
    width: 100%;
}
.pure-form .pure-input-3-4 {
    width: 75%;
}
.pure-form .pure-input-2-3 {
    width: 66%;
}
.pure-form .pure-input-1-2 {
    width: 50%;
}
.pure-form .pure-input-1-3 {
    width: 33%;
}
.pure-form .pure-input-1-4 {
    width: 25%;
}

/* Inline help for forms */
/* NOTE: pure-help-inline is deprecated. Use .pure-form-message-inline instead. */
.pure-form .pure-help-inline,
.pure-form-message-inline {
    display: inline-block;
    padding-left: 0.3em;
    color: #666;
    vertical-align: middle;
    font-size: 0.875em;
}

/* Block help for forms */
.pure-form-message {
    display: block;
    color: #666;
    font-size: 0.875em;
}

@media only screen and (max-width: 600px) {
    .pure-form button[type='submit'] {
        margin: 0.7em 0 0;
    }

    .pure-form input:not([type]),
    .pure-form input[type='text'],
    .pure-form input[type='password'],
    .pure-form input[type='email'],
    .pure-form input[type='url'],
    .pure-form input[type='date'],
    .pure-form input[type='month'],
    .pure-form input[type='time'],
    .pure-form input[type='datetime'],
    .pure-form input[type='datetime-local'],
    .pure-form input[type='week'],
    .pure-form input[type='number'],
    .pure-form input[type='search'],
    .pure-form input[type='tel'],
    .pure-form input[type='color'],
    .pure-form label {
        margin-bottom: 0.3em;
    }

    .pure-group input:not([type]),
    .pure-group input[type='text'],
    .pure-group input[type='password'],
    .pure-group input[type='email'],
    .pure-group input[type='url'],
    .pure-group input[type='date'],
    .pure-group input[type='month'],
    .pure-group input[type='time'],
    .pure-group input[type='datetime'],
    .pure-group input[type='datetime-local'],
    .pure-group input[type='week'],
    .pure-group input[type='number'],
    .pure-group input[type='search'],
    .pure-group input[type='tel'],
    .pure-group input[type='color'] {
        margin-bottom: 0;
    }

    .pure-form-aligned .pure-control-group label,
    .pure-form-aligned .pure-control-group select {
        margin-bottom: 0.3em;
        text-align: left;
        display: block;
        width: 100%;
    }
    .pure-form-aligned .pure-control-group input,
    .pure-form-aligned .pure-control-group textarea,
    .pure-form-aligned .pure-control-group select {
        display: block;
        width: 100%;
    }

    .pure-form-aligned .pure-controls {
        margin: 1.5em 0 0 0;
    }

    /* NOTE: pure-help-inline is deprecated. Use .pure-form-message-inline instead. */
    .pure-form .pure-help-inline,
    .pure-form-message-inline,
    .pure-form-message {
        display: block;
        font-size: 0.75em;
        /* Increased bottom padding to make it group with its related input element. */
        padding: 0.2em 0 0.8em;
    }
}

.pure-button.button-error {
    background: rgb(202, 60, 60);
    color: white;
    border-radius: 4px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}