@mixin fa-svg-icon($icon-name) {
    &.icon-#{$icon-name} {
        --svg: url('../images/#{$icon-name}.svg');
        -webkit-mask: var(--svg);
        mask: var(--svg);
        background-color: #ffffff;
    }
}
  
.icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    &.icon-large {
        width: 60px;
        height: 60px;
    }

    &.icon-android-alarm-clock {
        background: url('../images/android-alarm-clock.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
    &.icon-alert-circled {
        background: url('../images/alert-circled.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
    &.icon-load-c {
        background: url('../images/load-c.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.icon-load-c-black {
        background: url('../images/load-c-black.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.icon-caret-up-solid {
        background: url('../images/caret-up-solid.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.icon-ticket-solid {
        background: url('../images/ticket-solid.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }

    @include fa-svg-icon("door-open");
    @include fa-svg-icon("arrow-right-from-bracket");
    @include fa-svg-icon("trash-can");
    @include fa-svg-icon("mobile-screen");
    @include fa-svg-icon("rotate");
    @include fa-svg-icon("eject");
    @include fa-svg-icon("circle-notch");
    @include fa-svg-icon("circle");
    @include fa-svg-icon("link");
    @include fa-svg-icon("cash-register");
}

.google-pay {
    display: inline-block;
    background: url('../images/google-pay.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    width: 40px;
}

.apple-pay {
    display: inline-block;
    background: url('../images/apple-pay.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    width: 34px;
}