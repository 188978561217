@import './components/stripe';

#checkout-page {
    min-height: 100%;
    position: relative;

    iframe {
        height: 150px;    
    }
    
    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
.goldBadge {
    float:right;
    width: 80px;

    @media #{$medium-screen} {
        width: 120px;
    }
}

.checkout-page {
    margin: 0 auto 0 auto;
    padding-top: 50px;
    padding-bottom: 55px;
    position: relative;
    z-index: 3;

    @media #{$medium-screen} {
        max-width: 900px;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-around;
    }

    .booking-protect-coverage-items {
        font-size: 8pt;
        margin-top: 12px;
        margin-bottom: 4px;
        text-align: center;
        font-weight: 600;
    }

    .booking-protect-sales-message {
        margin-bottom: 10px;
    }
    
    .x-cover-logo-text {
        font-size: 12px;
        display: flex;
        flex-direction: row-reverse;
        margin-top: 6px;
    }
    
    .x-cover-sales-logo {
        width: 100px;
        margin-bottom: -4px;
    }

    .order-summary {
        background-color: whitesmoke;
        z-index: 3;
        margin: 5px 0;
        min-height: 30px;
        padding: 5px 20px;

        .ticket-line-item {
            color: black;
        }

        .event-image {
            margin: 0 auto;
            display: none;
            width: 100%;

            @media #{$medium-screen} {
                display: block;
            }
        }

        h3 {
            text-align: center;
            margin: 10px 0;
        }

        @media #{$medium-screen} {
            min-width: 240px;
            width: 240px;
            max-width: 400px;
            position: sticky;
            top: 60px;
            margin: 10px;
            background-color: white;
            padding: 5px 20px 20px 20px;

            .event-details {
                display: flex;
                background-color: white;
                text-align: center;
                flex-direction: column;

                .event-details-item {
                    margin: 2px 0;
                }
            }
        }
    }
    
    .cover-genius-header {
        padding-left: 9px;
        font-size: 18px;
        font-weight: 600;
    }
    


    .cover-genius-shield-ctn {
        width: min-content;
        height: min-content;
        padding: 4px;
        padding-top: 20px;

        .cover-genius-shield {
            width: 19.5px;
            height: 24.33px;
        }
    }

    .options {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .option {
            align-items: center;
            margin: 5px 0;
            padding: 10px;
            border: 1px solid whitesmoke;
            background-color: whitesmoke;
            border-radius: 4px;
            opacity: 0.5;

            &.first {
                background-color: rgb(221, 221, 238);
                opacity: 1;
            }

            .recommended-badge {
                float: right;
                font-weight: 400;
                font-size: 8pt;
                border: solid 1px black;
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.4);
                margin-left: 6px;
                margin-right: 4px;
                padding: 4px;
                color: white;
            }

            .recommended-badge-cover-genius {
                @extend .recommended-badge;
                border-color: rgb(0, 136, 0);
                background-color: rgb(0, 136, 0);
                text-align: center;
                padding: 3px;
                font-size: 9px;
                font-weight: 600;
                width: 85px;
            }
            
            .recommended-badge-cover-genius-ctn {
                display:flex;
                flex-direction: row-reverse;
                width: 30%;
                align-items: center;
            }
            
            .refund-protection-benefits-ctn {
                display: flex;
                flex-direction: column;
                padding-left: 22px;
                
                .tick-icon {
                    width: 14px;
                    height: 11.6667px;
                }
            }
            
            label {
                cursor: pointer;
            }

            .fullProtection {
                float: right;
                font-weight: 400;
                font-size: 8pt;
                border: solid 1px black;
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.4);
                margin-left: 8px;
                margin-top: 2px;
                margin-right: 4px;
                padding: 4px;
                color: white;
            }

            .option-header {
                display: flex;
                justify-content: space-between;
                font-weight: 400;
                align-items: center;
            }

            .option-body {
                margin-top: 10px;
            }

            &:hover {
                opacity: 1;
            }

            &.active {
                opacity: 1;
                box-shadow: 2px 2px 2px lightgrey;

                .option-header {
                    color: $primary-color;
                }

                .description {
                    margin-top: 10px;
                    font-size: 0.9em;
                    display: block;
                }
            }

            .description {
                display: none;
            }
        }
        
        .cgerror {
            border: 1px solid red !important;
        }
        
        .option-cover-genius {
            @extend .option;

            background-color:#fff;
            opacity: 1;
            border: 1px solid #D3D3D3;
            
            &.active {
                border-color: $primary-color;
                .option-header {
                    color: #000000;
                }
            }

            .cover-genius-title-ctn {
                width: 100%;
                display:  flex;
                margin-bottom: 3px;
                
                
                .cover-genius-title {
                    width: 70%;
                    
                    .header-text {
                        padding-left: 3px;
                        font-weight: 600;
                        font-size: 16px;
                    }
                }
            }
            
            @media (max-width: 850px) {
                .cover-genius-title-ctn {
                    flex-direction: column;

                    .recommended-badge-cover-genius-ctn {
                        width: 118px;
                    }

                    .cover-genius-title {
                        display: table;
                        width: 100%;
                        .header-text {
                            display: table-cell;
                            padding-left: 8px;
                        }
                    }
                }
            }
        }
    }

    .terms {
        display: flex;
        align-items: flex-start;
        max-width: 620px;

        &.has-error {
            font-weight: bold;
        }

        input {
            width: 17px;
            height: 17px;
        }

        label {
            margin: 0;
            margin-left: 20px;
        }

        .error-description {
            display: none;
            font-weight: 500;
        }
    }

    .name {
        font-weight: 600;
        font-size: 1em;
    }

    .details {
        color: grey;
        fill: grey;
        font-size: 0.9em;
        text-align: left;

        .detail-item {
            text-align: left;
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }
    }
}

.StripeElement {
    background-color: white;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid lightgrey;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.add-ons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.add-on {
    width: 180px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px lightgrey;
    margin: 10px;
    &:hover {
        box-shadow: -2px 10px 20px lightgrey;
        cursor: pointer;
    }
}
.add-on img {
    width: 140px;
}

.box {
    background-color: white;
    min-height: 30px;
    margin: 5px 0;
    padding: 5px 20px;
    outline: none;
    @media #{$medium-screen} {
        margin: 10px;
    }
    .box-content {
        padding: 0 0 10px 0;
    }
    .box-header {
        display: flex;
        justify-content: space-between;
        align-items: top;
    }
    .box-detail-item {
        margin: 5px 0;
    }
}

.grow {
    flex-grow: 1;
    z-index: 3;
}

.address {
    background-color: whitesmoke;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

a.address-link {
    cursor: pointer;
    color: black;
    text-decoration: underline;
    font-size: 0.9em;
    padding-bottom: 3px;
}

.suggestion {
    display: block;
    cursor: pointer;
    font-size: small;
    padding: 8px 0;

    .email {
        border-bottom: 1px solid black;
        font-weight: 500;
    }
}

iframe {
    border: 0;
    width: 100%;
}

#cardData {
    iframe {
        border: 0;
        width: 100%;
        max-width: 600px;
        height: inherit;
    }
}

.zip-selection {
    @import url('https://fonts.googleapis.com/css?family=Rubik:400,500');
    display: flex;
    align-items: center;

    * {
        font-family: Rubik;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        font-size: 4vw;
        color: #21285e;
        letter-spacing: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @media (min-width: 415px) {
        * {
            font-size: 16px;
        }
    }

    .flex-container {
        display: flex;
        align-items: center;
        padding-left: 15px;
    }

    .ziplogo {
        background-image: url(../images/primary-zip.svg);
        width: 60px;
        height: 20px;
        background-repeat: no-repeat;
    }

    strong {
        font-weight: 500;
    }

    .divider {
        min-width: 2px;
        height: 20px;
        background: #00aeb8;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        margin-left: 8px;
        margin-right: 8px;
    }

    @media (min-width: 415px) {
        .divider {
            height: 27px;
        }
    }

    .learn-more {
        color: #21285e;
        font-size: 3.5vw;
        margin-left: 2px;
        text-decoration: underline;
        align-self: center;
    }

    @media (min-width: 415px) {
        .learn-more {
            font-size: 12px;
        }
    }

    @media (max-width: 317px) {
        .learn-more {
            margin-left: 0;
        }
    }

    .outer-widget {
        cursor: pointer;
    }
}

.zip-pay-text {
    display: flex;
    align-items: center;
    justify-content: center;
    .zipPayWhite {
        background-image: url(../images/zipPayWhite.png);
        width: 60px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        margin-left: 6px;
    }
}

    
