#requires-action-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        fill: black;
    }
    
    .status {
        font-size: 2em;
        margin: 0 0.5rem;
        
        .small {
            margin-top: 0.25rem;
            font-size: 0.6em;
            height: 2rem;
            overflow: visible;
        }
    }
}
