﻿.event-image {
    width: 100%;

    @media #{$medium-screen} {
        width: 400px;
    }

    img {
        width: 100%;
        @media #{$medium-screen} {
            border-radius: 5px;
            position: absolute;
            top: -20px;
            left: -20px;
            background-color: white;
            width: 300px;
            margin: 0;
            padding: 0;
            border: 4px solid white;
            transform: rotateZ(-5deg);
        }
    }
}
.event-container {
    background-color: white;
    position: relative;
    z-index: 1;
    @media #{$medium-screen} {
        background-color: inherit;
    }
}

.event-header {
    @media #{$medium-screen} {
        display: flex;
        padding: 5px 20px;
        background-color: whitesmoke;
        border-top-right-radius: 4px;
        min-height: 120px;
    }

    .event-name {
        line-height: 1.5em;
    }

    .event-header-details {
        text-align: center;
        font-size: 0.8em;

        @media #{$medium-screen} {
            font-size: 1em;
            padding: 10px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 500px;
            margin: 10px 0;
            text-align: inherit;
            padding: inherit;
        }
    }
}

.outlet-door {
    .event-content-container {
        padding: 0 20px 20px 20px;
    }
    
    .event-name {
        margin-bottom: 0;
    }
}

@media #{$medium-screen} {
    .outlet-door {
        .event-header {
            min-height: 0;
            padding: 0 0 0 10px;
            background-color: white;

            .event-header-details {
                margin: 0;
            }
        }

        .event-details {
            margin-top: 0;
        }

        .content {
            margin-top: 0;
            padding-top: 60px;
        }

        .event-content-container {
            padding: 5px 20px 20px 20px;
        }
    }
}

@media #{$touch-screen} {
    .outlet-door .tickets {
        .hide-medium {
            display: block;
            visibility: inherit;
        }

        .hide-mobile {
            display: none;
            visibility: hidden;
        }
    }
}

.tickets-container {
    h3 {
        margin-top: 20px;
        margin-bottom: 5px;
    }
}
    

.event-tags {
    text-align: center;
    line-height: 1.5em;
    margin-bottom: 5px;
    @media #{$medium-screen} {
        display: block;
        margin-bottom: 15px;
    }
    .event-tag {
        color: black;
        background-color: rgba(0, 0, 0, 0.1);
        display: inline-block;
        font-size: 0.7em;
        padding: 1px 7px;
        border-radius: 2px;
        margin-right: 3px;
        margin-top: 5px;
        @media #{$medium-screen} {
            color: white;
            background-color: rgba(0, 0, 0, 0.4);
            font-size: 12px;
            padding: 4px 9px;
            line-height: 18px;
            margin-right: 8px;
            margin-top: 10px;
        }
    }
}

h1 {
    margin: 5px 0;
    font-size: 1.8em;
}

.presented-by,
.special-guests {
    font-size: 0.9em;
    line-height: 1.5em;
}

.event-details {
    font-size: 0.9em;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    @media #{$medium-screen} {
        display: flex;
        background-color: whitesmoke;
        flex-direction: row;
        margin-top: 10px;
    }

    .event-details-item {
        display: flex;
        align-items: center;
    }

    svg {
        fill: rgba(0, 0, 0, 0.6);
        margin-right: 3px;
    }
}

.event-venue-name {
    margin-right: 5px;
}

.event-content-container {
    background-color: white;
    padding: 5px 20px 20px 20px;
}

.event-description {
    margin-top: 20px;
    img {
        max-width: 100%;
    }
}

h3.tickets-header  {
    display: none;
    margin-top: 10px;
    margin-bottom: 10px;
    @media #{$medium-screen} {
        display: flex;
        justify-content: space-between;
    }
}

.ticket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    border: 1px solid whitesmoke;
    padding: 10px;
    box-shadow: 1px 1px 3px lightgrey;
    border-radius: 4px;
    border-left: 4px solid lightgrey;
    transition: border-color ease-in-out 150ms;

    @media #{$medium-screen} {
        padding: 10px 20px;
    }

    &.active {
        border-left: 4px solid $primary-color;

        .ticket-name {
            color: $secondary-text-color;
        }

        .ticket-price {
            color: $secondary-text-color;
        }
    }

    .ticket-name-container {
        flex: 1;
    }

    .ticket-tag {
        background-color: rgba(0, 0, 0, 0.1);
        color: black;
        font-size: 12px;
        display: inline-block;
        border-radius: 2px;
        padding: 5px 9px;
        max-width: 150px;
        text-align: center;
        line-height: 18px;
        margin-left: 5px;
    }

    .ticket-name {
        font-weight: 400;
        display: flex;

        svg {
            margin-right: 5px;
            margin-left: -5px;
            fill: $primary-color;
            height: 24px;
            width: 24px;
        }
    }

    .ticket-description {
        font-size: 0.8em;
        padding-right: 10px;
    }

    .ticket-price {
        font-weight: 400;
        margin-right: 10px;
    }

    .ticket-selection {
        select {
            max-width: 70px;
            max-height: 50px;
            font-size: 16px;
        }

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 0;
        font-weight: 500;

        .ticket-number {
            flex: 1;
            margin: 0 10px;
            font-size: 1.2em;
        }

        button.ticket-button {
            flex-grow: 0;
            margin: 0;
            padding: 0;
            background-color: inherit;
            outline: none;
            border: 0;
            display: flex;

            svg {
                width: 40px;
                height: 40px;
                fill: $primary-color;
            }

            &[disabled] {
                svg {
                    fill: lightgrey;
                }
            }

            &:focus {
                outline: none;
                border: 0;
            }

            &[disabled] {
                svg {
                    fill: lightgrey;
                }
            }
        }
    }
}

.auto-scan-selection {
    display: flex;
    align-items: center;
    padding: 5px 0 15px 0;
    
    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    .toggle-label {
        cursor: pointer;
        text-indent: -9999px;
        width: 60px;
        height: 34px;
        background: grey;
        display: block;
        border-radius: 50px;
        position: relative;
        margin-right: 10px;

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 30px;
            height: 30px;
            background: #fff;
            border-radius: 50px;
            transition: 0.3s;
        }

        &:active:after {
            width: 40px;
        }
    }

    input:checked {
        + .toggle-label {
            background: #10893e;
            
            &:after {
                left: calc(100% - 2px);
                transform: translateX(-100%);
            }
        }
    }
    .description-label {
        font-weight: 500;
    }

    input:disabled {
        + .toggle-label {
            background-color: #d3d3d3;

            &:after {
                background-color: #f3f3f3;
            }
        }
    }
}


.background-image {
    position: fixed;
    z-index: 0;
    top: -60px;
    left: -60px;
    right: -60px;
    bottom: -60px;
    will-change: transform;
    display: none;
}

@supports (-webkit-filter: blur(50px)) or (filter: blur(50px)) {
    @media #{$medium-screen} {
        .background-image {
            display: block;
        }

        #blurred-image {
            width: 100%;
            height: 100%;
            filter: blur(50px);
        }
    }
}

.price-description{
    font-size: 0.6em;
    white-space: normal;
    margin-left: 10px;
    margin-right: 10px;
}
.share-event-description {
    font-weight: 300;
    font-size: 0.8em;
    text-align: center;
    color: gray;
}

.social-share {
    display: flex;
    text-align: center;
    justify-content: center;

    .social-share-link {
        line-height: 25px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border-radius: 100%;
        background-color: rgba(0,0,0,.7);
        margin-right: 7px;

        svg {
            fill: rgb(255, 255, 255);
            width: 25px;
            height: 25px;
        }

        &:hover {
            cursor: pointer;
            background-color: $primary-color;
        }
    }
}


.waitlist-container {
    font-size: 1em;
    display: flex;
    background-color: lightblue;
    font-weight: 400;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    color: black;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    min-height: 100px;
}

.waitlist-notickets-text {
    font-size: 0.8em;
}
.waitlist-error-text {
    color: red;
    font-weight: bold;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.flex {
    display: flex;
    &.flex-wrap {
        flex-wrap: wrap;
    }
}

.flex.ai-center {
    align-items: center;
}

.flex.jc-center {
    justify-content: center;
}

.flex.jc-space-between {
    justify-content: space-between;
}

.settings-input {
    width: 20px;
    height: 20px;
}

.seat-selection {
    position: absolute;
    bottom: 8%;
    left: 0px;
    z-index: 1;
    margin-bottom: -2px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 90%;
    margin-left: 5%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px -1px 4px 0px;
    transition: height 1s ease;
}

.seat-selection-closed {
    height: 25px;
    position: absolute;
    bottom: 8%;
    left: 0px;
    z-index: 3;
    margin-bottom: -2px;
    padding-bottom: 15px;
    padding-top: 5px;
    width: 90%;
    margin-left: 5%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px -1px 4px 0px;
    transition: height 1s ease;
}
.seating-selection-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.seating-arrow-closed {
    color: #0077c8;
    height: 35px;
}

.seating-arrow-open {
    color: #0077c8;
    height: 35px;
    transform: rotate(180deg);
    margin-bottom: 18px;
}

.ticket-heading {
    text-transform: uppercase;
    color: #0077c8;
    font-weight: 800;
    font-size: 14px;
}

.ticket-text {
    font-size: 14px;
}

.ticket-name-bold {
    font-weight: 700;
}

.locked-background {
    height: 600px;
    width: 100%;
    background-color: rgb(0 0 0 / 10%);
    z-index: 1;
    position: absolute;
}

.dialog-modal-overlay {
    height: 600px;
    width: 100%;
    z-index: 1;
    position: absolute;
    background-color: #edededfa;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: .4s cubic-bezier(.19,1,.22,1) zoom-in;
}

.dialog-modal-container {
    display:flex;
    flex-direction: column;
}

.dialog-modal-label-details {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
}

.floating-dialog-panel {
    position: relative;
    overflow-y: auto;
    border-radius: 5px;
    background-color: var(--background-color);
}

.seat-dialog-selection-container  {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
}

.seat-dialog-selection-header-container {
     display: flex;
     flex-direction: column;
     align-items: center;
    animation: zoom-in 0.3s ease;
}

.seat-dialog-selection-header-container  .section-header {
    font-weight: 400;
    font-size: medium;
}


.seat-dialog-selection-header-container  .option-header {
    font-weight: 400;
    margin-bottom: 15px;
    animation: zoom-in 0.3s ease;
}

.seat-dialog-selection-header-container  .seat-numbers {
    font-weight: 400;
    font-size: medium;
    margin-bottom: 15px;
}

.seat-dialog-selection-ticket-container {
    background: white;
    width: 250px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-family: Helvetica, Roboto, Arial, sans-serif;
    font-size: 15px;
    box-shadow: 0 0 1px grey, 0 1px 1px 1px grey, 0 3px 6px grey;
    animation: zoom-in 0.3s ease;
}

.seat-dialog-selection-container .ticket-option-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    
}

.seat-dialog-selection-container .ticket-option-container:hover {
    transition: background-color 0.2s ease;
    background-color: #f8f8f8;
    border-radius: 5px;
}

.seat-dialog-selection-container .cancel-button-container {
    cursor: pointer;
    box-sizing: border-box;
    color:  $primary-color;
    text-align: center;
    letter-spacing: .03em;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    height: 38px;
    padding: 0 24px;
    font-weight: 500;
    display: flex;
    animation: zoom-in 0.3s ease;
}

.ticket-option-container .ticket-number {
    font-weight: 400;
}


.seat-selection-picker {
    height: 600px; 
    margin-bottom: 60px;
}

.seat-selection-hidden {
    display: none;
}

.seat-selection-picker-disabled {
    pointer-events: none;
}

.seat-selection-auto-scan {
    margin-bottom: 35px;
}

@keyframes zoom-in {
    0% {
        transform: scale(0, 0);
    }
    100% {
        transform: scale(1, 1);
    }
}