$padding: 30px;
#receipt-page {
    z-index: 1;
    min-height: 100%;
    position: relative;

    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .social-share-link {
        background-color: $primary-color;
        &:hover {
            filter: brightness(85%);
        }
    }
}

.receipt-container {
    max-width: 650px;
    min-height: 300px;
    margin: 0 auto 0 auto;
    padding-top: 50px;
    padding-bottom: 55px;
    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 5px;
        color: $secondary-text-color;
        font-weight: 500;
    }
}

.receipt {
    background-color: white;
    .receipt-body {
        font-size: 0.9em;
    }
    .section {
        border-bottom: 1px dashed whitesmoke;
        border-radius: 4px;

        padding: 10px $padding;
        h3 {
            margin: 8px 0;
            font-size: 1.3em;
            font-weight: 500;
        }
    }
    .receipt-address {
        @media #{$medium-screen} {
            display: flex;
            justify-content: space-between;
        }
        padding: 10px $padding;
    }

    .line-item {
        border-bottom: 1px solid whitesmoke;
        margin: 8px 0;
    }

    .receipt-footer {
        text-align: center;
    }

    .receipt-header {
        display: flex;
        flex-direction: column;
        padding: 10px 5px;
        @media #{$medium-screen} {
            flex-direction: row;
            padding: $padding;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    .receipt-date {
        color: grey;
        margin-left: 70px;
        font-size: 0.9em;
        @media #{$medium-screen} {
            color: black;
            margin-left: 0;
        }
    }

    .receipt-header-title {
        text-align: center;
        svg {
            width: 50px;
            margin-right: 20px;
        }
        h2 {
            margin: 0;
            color: $secondary-text-color;
        }
        padding: 20px 0;
    }

    .receipt-highlight {
        border-left: 5px solid $primary-color;
        padding: 10px 20px;
    }

    .description {
        font-size: 0.9em;
        margin-top: 3px;
        color: rgba(0, 0, 0, 0.6);
    }

    .details-items {
        .item {
            margin: 8px 0;
            display: flex;
            border-bottom: 1px solid whitesmoke;
            justify-content: space-between;

            @media #{$medium-screen} {
                justify-content: inherit;
            }
            .detail-title {
                width: 220px;
                margin-right: 20px;
                font-style: italic;
            }
            .detail-description {
                text-align: right;
                @media #{$medium-screen} {
                    text-align: left;
                }
            }
        }
    }

    .grid-event-recommendation {
        margin: 10px;
        width: 250px;
    }
    a.event-recommendation {
        text-decoration: none;
    }
    .event-recommendation {
        display: -ms-flexbox;
        display: flex;
        background-color: whitesmoke;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 10px;
    }
    .event-recommendation:hover {
        -webkit-box-shadow: 2px 2px 2px lightgrey;
        box-shadow: 2px 2px 2px lightgrey;
    }

    .event-recommendation img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: 4px solid $primary-color;
        width: 250px;
        height: 115px;
    }

    .event-recommendation .details {
        display: -ms-flexbox;
        display: flex;
        width: 250px;
        overflow: hidden;
        height: 85px;
    }

    .event-recommendation .event-date {
        background-color: $primary-color;
        font-weight: 700;
        width: 40px;
        padding: 5px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        color: white;
        line-height: 17px;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .event-recommendation .event-date .month {
        font-size: 16px;
    }
    .event-recommendation .event-date .year {
        font-size: 14px;
    }
    .event-recommendation .event-date .day {
        font-size: 16px;
    }

    .event-recommendation .summary {
        font-size: 11px;
        margin: 8px;
        text-align: left;
    }
    .event-recommendation .summary .event-name {
        font-size: 13px;
        color: #212121;
        margin-bottom: 3px;
        max-height: 58px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
    .event-recommendation .venue-name {
        color: grey;
    }
    .event-recommendation .venue-state {
        color: darkgrey;
        margin-left: 2px;
    }
}
