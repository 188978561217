body {
    &.modal-open {
        overflow: hidden;
        width: 100%;
    }
    @media (min-width: 768px) {
        &.modal-open {
            overflow: hidden;
        }
    }
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: block;
    pointer-events: none;

    &.fade .modal-dialog {
        transition: transform 0.3s ease-out;
        transform: translate(0, -25%);
    }
    &.in .modal-dialog {
        transform: translate(0, 0);
    }
    .modal-dialog {
        position: relative;
        width: 90%;
        margin: 70px auto;
        max-width: 800px;
    }
    .modal-content {
        position: relative;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #999;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        text-align: center;
        outline: 0;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
        pointer-events: auto;

        .close-button {
            background-color: #DDD;
            border-color: #CCC;
            border-radius: 8px;
            margin-bottom: 4px;
            margin-top: 6px;
            font-size: 10pt;
            color: #121212;
        }
    }
    .modal-header {
        padding: 10px 15px;
        text-align: center;
        border-bottom: 1px solid whitesmoke;
        background-color: whitesmoke;
    }
    .modal-header .close {
        margin-top: -2px;
    }
    .modal-title {
        margin: 0;
        line-height: 1.42857143;
    }
    .modal-body {
        position: relative;
        padding: 8px 8px;

        .remind-me-text {
            font-size: 10pt;
            line-height: 14pt;
        }
    }
    .modal-footer {
        padding: 15px;
        text-align: center;
        border-top: 1px solid #e5e5e5;
        color: $primary-color;
        font-weight: 600;
    }
    .modal-footer .btn + .btn {
        margin-bottom: 0;
        margin-left: 5px;
    }
    .modal-footer .btn-group .btn + .btn {
        margin-left: -1px;
    }
    .modal-footer .btn-block + .btn-block {
        margin-left: 0;
    }
    .modal-scrollbar-measure {
        position: absolute;
        top: -9999px;
        width: 50px;
        height: 50px;
        overflow: scroll;
    }
    @media (min-width: 768px) {
        .modal-dialog {
            width: 600px;
            margin: 70px auto;
        }
        .modal-content {
            -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        }
        .modal-body {
            padding: 10px;
        }
        .modal-sm {
            width: 300px;
        }
    }
    @media (min-width: 992px) {
        .modal-lg {
            width: 900px;
        }
    }
    &.modal-error {
        .modal-header {
            background-color: $modal-error-color;
            color: white;
        }
        .modal-footer .link-button {
            color: $modal-error-color;
            font-weight: 600;
        }
    }
    &.modal-info {
        .modal-header {
            background-color: $primary-color;
            color: white;
        }

        .modal-footer .link-button {
            color: $primary-color;
            font-weight: 600;
        }
    }
    &.modal-confirmation {
        .modal-header {
            display: none;
        }

        .modal-body {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 10px;

            .modal-body-content {
                display: flex;
                align-items: flex-start;
                flex-direction: row;
                margin-top: 5px;

                .modal-icon {
                    flex: 0 0 auto;
                    padding-top: 5px;
                    color: #dc2626;
                }

                .modal-text {
                    flex-grow: 1;
                    text-align: left;
                    margin-left: 10px;
                    
                    p {
                        margin-top: 10px;
                        margin-bottom: 10px
                    }

                    h3 {
                        margin-top: 0;
                        color: rgb(17 24 39);
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .modal-footer {
            display: flex;
            justify-content: flex-end;

            .modal-button {
                display: inline-flex;
                justify-content: center;
                padding: 0.5rem 0.75rem;
                font-size: 0.875rem;
                font-weight: 600;
                border-radius: 0.375rem;
                width: auto;
                margin-right: 0.25rem;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

                &:last-child {
                    margin-right: 0;
                }

                &.modal-button-cancel {
                    background-color: #fff;
                    color: rgb(17, 24, 39);
                    border: 1px solid rgb(209, 213, 219);
                    min-width: 80px;

                    &:hover {
                        background-color: rgb(249, 250, 251);
                    }
                }

                &.modal-button-confirm {
                    background-color: rgb(220, 38, 38);
                    color: #fff;
                    min-width: 120px;

                    &:hover {
                        background-color: rgb(239, 68, 68);
                    }
                }

                @media (max-width: 317px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 0.5rem; // Adjust as needed

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

        }
    }
}
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    &.fade {
        filter: alpha(opacity=0);
        opacity: 0;
    }
    &.in {
        filter: alpha(opacity=50);
        opacity: 0.5;
    }
}