@import 'base';
@import 'helpers';
@import '_variables';
@import 'animations';
@import 'forms';
@import 'buttons';
@import 'icons';
@import 'checkout';
@import '../../Features/Shared/VueComponents/modal';
@import '../../Features/Event/index';
@import '../../Features/Processing/index';
@import '../../Features/RequiresAction/index';
@import '../../Features/Thankyou/index';

@import './components/date-of-birth';

[v-cloak] {
    display: none;
}

hack-to-get-favicon-deplloyed-to-dist-directory {
    background-image: url(../images/favicon.png);
}

html {
    background-color: whitesmoke;
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    color: $primary-text-color;
}


.header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    height: 45px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $header-color;
    @media #{$medium-screen} {
        padding: 0 10px;
    }
    .logo {
        margin-left: 5px;
        @media #{$medium-screen} {
            margin-left: 10px;
        }
    }

    .link-group {
        display: flex;
        align-items: center;
    }

    .link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: white;
        margin: 0 5px;
        @media #{$medium-screen} {
            margin: 0 10px;
        }
    }
}

.preview {
    position: fixed;
    text-align: left;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 4;
    background-color: $preview-color;
    color: white;
    font-weight: 500;
    padding: 10px 20px;
    opacity: 0.8;
}

.previewOrderWarning {
    background-color: #d70303;
    width: 90%;
    border-radius: 10px;
    font-weight: 400;
    font-size: 0.9em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #eeeeee;
    padding: 4px;
    box-shadow: 4px 4px #333333;
}

.content {
    margin: 0 auto 0 auto;
    padding-top: 42px;
    padding-bottom: 20px;
    line-height: 1.6em;
    max-width: 700px;

    @media #{$medium-screen} {
        margin: 0 auto 0 auto;
        padding: 0;
        padding-top: 80px;
    }
}


button,
a {
    &.button-small {
        font-size: 85%;
    }

    &.pure-button-primary {
        background-color: $primary-color;
        color: white;
        width: 100%;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 10px;
        padding: 10px 0;

        .icon {
            margin: 0 5px;
        }
    }

    &.pure-button-remindme {
        background-color: $social-share-color;
        color: white;
        text-transform: inherit;
        margin-top: 0;
        padding: 4px;
        border-radius: 6px;
        border-width: 1px;
        border-style: solid;
        height: 36px;
        font-size: 9pt;
    }

    &.button-outline-danger {
        background-color: $primary-color;
        color: white;
        width: 100%;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 10px;
        padding: 10px 0;

        .icon {
            margin: 0 5px;
        }
        
        color: #dc3545;
        background-color: transparent;
        background-image: none;
        border: 1px solid #dc3545;
    }


}

@media #{$medium-screen} {
    .hide-medium {
        display: none;
        visibility: hidden;
    }
}
.hide-mobile {
    display: none;
    visibility: hidden;
    @media #{$medium-screen} {
        display: block;
        visibility: inherit;
    }
}

.error {
    color: $error-color;
}
.primary {
    color: $primary-color;
}

.is-transparent {
    background-color: inherit;
}

.info {
    background-color: #fcf8e3;
    color: #392708;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 2px solid #ecdd94;
    border-radius: 3px;
    font-weight: bold;
    
    a {
        color: #392708;
    }
}

.alert {
    background-color: #fff3cd;
    color: #392708;
    fill: #392708;
    text-align: center;
    padding: 5px 17%;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1em;
    box-shadow: 2px 2px 2px whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;

    svg {
        margin-right: 10px;
        height: 25px;
    }

    &:focus {
        outline: none;
    }
    
    &.alert-success {
        background-color: #dff0d8;
        color: #3c763d;
        border-color: #d6e9c6;
        a {
            color: #392708;
        }
    }

    &.alert-info {
        background-color: #cce5ff;
        color: #004085;
        border-color: #bce8f1;
    }
}


.error-message {
    display: none;
}

.has-error {
    color: $error-color;
    .error-message {
        display: block;
        color: $error-color !important;
    }
    input, textarea {
        border-color: $error-color !important;
        box-shadow: 0 0 2px $error-color !important;

        &:focus {
            outline: none;
        }
    }
    select {
        border-color: $error-color !important;
        box-shadow: 0 0 2px $error-color !important;

        &:focus {
            outline: none;
        }
    }
}

.suggestion {
    display: block;
    cursor: pointer;
    @media #{$medium-screen} {
        margin-left: 180px;
    }

    .email {
        border-bottom: 1px solid black;
        font-weight: 500;
    }
}

.line-item {
    display: flex;
    justify-content: space-between;
    margin: 9px 0;
    @media #{$medium-screen} {
        margin: 15px 0;
    }
    &.total {
        font-weight: 600;
        text-transform: uppercase;
    }
    &.ticket-line-item {
        font-size: 0.9em;
        margin: 10px 0;
        color: rgba(0, 0, 0, 0.5);
    }
}

footer {
    background-color: $footer-color;
    color: white;
    padding: 10px;
    font-size: 0.8em;

    @media #{$medium-screen} {
        display: flex;
        justify-content: space-between;
    }

    a {
        color: white;
    }
}

a {
    color: $secondary-text-color;
}

.add-to-calendar {
    display: flex;
    flex-direction: column;
    a {
        text-decoration: none;
        color: black;
    }
    span {
        display: flex;
        align-items: center;
    }
    .calendar-item {
        padding: 0 20px;
        height: 60px;
        border-bottom: 1px solid whitesmoke;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
            cursor: pointer;
            background-color: whitesmoke;
        }
    }
    svg {
        width: 20px;
        height: 20px;
    }
}

form {
    position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1010;
  background-color: #fff;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  /* IE 5-7 */
  filter: alpha(opacity=60);
  background: rgba(255, 255, 255, 0.6);
  cursor: wait;
}

.payPal {
    background-color: #ffc439 !important;
    height: 55px;
    color: #000  !important;
}

.tixelLogo {
    background: url('../images/tixel-logo.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    width: 50px;
    height: 22px;
    display: inline-block;
}