.is-flex {
    display: flex;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex.flex-row {
    flex-direction: row;
}

.flex.flex-col {
    flex-direction: column;
}

.flex.justify-normal {
    justify-content: normal;
}

.flex.justify-start {
    justify-content: flex-start;
}

.flex.justify-end {
    justify-content: flex-end;
}

.flex.justify-center {
    justify-content: center;
}

.flex.justify-between {
    justify-content: space-between;
}

.flex.justify-around {
    justify-content: space-around;
}

.flex.justify-evenly {
    justify-content: space-evenly;
}

.flex.justify-stretch {
    justify-content: stretch;
}

.flex.items-start {
    align-items: flex-start;
}

.flex.items-end {
    align-items: flex-end;
}

.flex.items-center {
    align-items: center;
}

.flex.items-baseline {
    align-items: baseline;
}

.flex.items-stretch {
    align-items: stretch;
}

.flex.gap-0 {
    gap: 0;
}

.flex.gap-0\.5 {
    gap: 0.125rem;
}

.flex.gap-1 {
    gap: 0.25rem;
}

.flex.gap-1\.5 {
    gap: 0.375rem;
}

.flex.gap-2 {
    gap: 0.5rem;
}

.flex.gap-2\.5 {
    gap: 0.625rem;
}

.flex.gap-3 {
    gap: 0.75rem;
}

.flex.gap-3\.5 {
    gap: 0.875rem;
}

.flex.gap-4 {
    gap: 1rem;
}

.flex.gap-5 {
    gap: 1.25rem;
}

.flex.gap-6 {
    gap: 1.5rem;
}

.flex.gap-7 {
    gap: 1.75rem;
}

.flex.gap-8 {
    gap: 2rem;
}
