#processing-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        fill: black;
    }

    .small {
        font-size: 0.5em;
    }

    .status {
        font-size: 2em;
        margin: 0 0.5rem;

        &.processing {
            .small {
                margin-top: 0.25rem;
                font-size: 0.6em;
                height: 2rem;
                overflow: visible;
            }

            .fade-enter-active, .fade-leave-active {
                transition: opacity .5s;
            }
            .fade-enter-from, .fade-leave-to {
                opacity: 0;
            }
        }
    }
}

.spinning {
    animation: spin 1s infinite linear;
}

.success {
    .checkmark__circle {
        stroke: green;
    }
    .checkmark {
        stroke: green;
        box-shadow: inset 0 0 0 #7ac142;
    }
}

.error {
    .checkmark__circle {
        stroke: $error-color;
    }
    .checkmark {
        stroke: $error-color;
        box-shadow: inset 0 0 0 $error-color;
    }
    .error-message-content {
        word-break: break-word;
    }
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    border-radius: 50%;
    stroke-width: 2;
    stroke-miterlimit: 10;
    animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.back-link-container {
    position: absolute;
    display: flex;
    left: 0;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
    .back-link {
        margin-top: 50px;
        max-width: 350px;
        text-decoration: none;
        border: 1px solid $error-color;
        border-radius: 4px;
        padding: 5px 50px;
        background-color: $error-color;
        color: white;
        width: 100%;
        margin: 0 20px;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes fill {
    100% {
        box-shadow: inset 0 0 0 30px #fff;
    }
}
