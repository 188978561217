@import '../_variables';

.StripeElement {
    background-color: white;
    border-radius: 4px;
    border: 1px solid transparent;
}

.StripeElement--focus {
    border: 1px solid $primary-color;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.field {
    font-family: 'Open Sans';
}
