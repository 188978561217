
@import '_variables';

/*!
Pure v1.0.0
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/yahoo/pure/blob/master/LICENSE.md
*/
.pure-button {
    /* Structure */
    display: inline-block;
    zoom: 1;
    line-height: normal;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* Firefox: Get rid of the inner focus border */
.pure-button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

/* Inherit .pure-g styles */
.pure-button-group {
    letter-spacing: -0.31em; /* Webkit: collapse white-space between units */
    *letter-spacing: normal; /* reset IE < 8 */
    *word-spacing: -0.43em; /* IE < 8: collapse white-space between units */
    text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */
}

.opera-only :-o-prefocus,
.pure-button-group {
    word-spacing: -0.43em;
}

.pure-button-group .pure-button {
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

/*csslint outline-none:false*/

.pure-button {
    font-family: inherit;
    font-size: 100%;
    padding: 0.5em 1em;
    color: #444; /* rgba not supported (IE 8) */
    color: rgba(0, 0, 0, 0.8); /* rgba supported */
    border: 1px solid #999; /*IE 6/7/8*/
    border: none rgba(0, 0, 0, 0); /*IE9 + everything else*/
    background-color: #e6e6e6;
    text-decoration: none;
    border-radius: 2px;
}

.pure-button-hover,
.pure-button:hover,
.pure-button:focus {
    /* csslint ignore:start */
    filter: alpha(opacity=90);
    /* csslint ignore:end */
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(transparent),
        color-stop(40%, rgba(0, 0, 0, 0.05)),
        to(rgba(0, 0, 0, 0.1))
    );
    background-image: -webkit-linear-gradient(
        transparent,
        rgba(0, 0, 0, 0.05) 40%,
        rgba(0, 0, 0, 0.1)
    );
    background-image: linear-gradient(
        transparent,
        rgba(0, 0, 0, 0.05) 40%,
        rgba(0, 0, 0, 0.1)
    );
}
.pure-button:focus {
    outline: 0;
}
.pure-button-active,
.pure-button:active {
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
        0 0 6px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
        0 0 6px rgba(0, 0, 0, 0.2) inset;
    border-color: #000\9;
}

.pure-button[disabled],
.pure-button-disabled,
.pure-button-disabled:hover,
.pure-button-disabled:focus,
.pure-button-disabled:active {
    border: none;
    background-image: none;
    /* csslint ignore:start */
    filter: alpha(opacity=40);
    /* csslint ignore:end */
    opacity: 0.4;
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none;
    pointer-events: none;
}

.pure-button-hidden {
    display: none;
}

.pure-button-primary,
.pure-button-selected,
a.pure-button-primary,
a.pure-button-selected {
    background-color: $primary-color;
    color: #fff;
}

.pure-button-secondary {
    background-color: lightsteelblue;
    width: 100%;
    color: #666666;
}

/* Button Groups */
.pure-button-group .pure-button {
    margin: 0;
    border-radius: 0;
    border-right: 1px solid #111; /* fallback color for rgba() for IE7/8 */
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.pure-button-group .pure-button:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.pure-button-group .pure-button:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-right: none;
}

.link-button {
    background: transparent;
    border: 0;
    color: $primary-color;
    font-weight: 600;
}
