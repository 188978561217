.date-of-birth {
    display: inline-flex;

    input[type='tel'].date-input {
        width: 50px;
        text-align: right;
        display: inline-block;
        flex-grow: 0;
        &.year-input {
            width: 70px;
        }
    }
}
